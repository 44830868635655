import { LinearProgress, Paper, TableFooter, TablePagination, TableSortLabel, Toolbar, Typography, TextField, } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { getComparator, stableSort } from "../../../middleware/helper";
import { FormatCurrency, FormatPercent } from "../../common/formatter";
import { logoDataUrl } from "./export-logo";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import "./marketShare.css"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const currentYear = new Date(2021, 11, 31).getFullYear();
const years = [currentYear + 2, currentYear + 1, currentYear,
currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4];


function MarketShareTableRow({ row }) {
    // if (Number.isInteger(row.total_sales) && row.total_sales  > 0) {
    //     console.log(row.total_sales)
    return (
        <React.Fragment>
            <TableRow row={row}>
                <TableCell component="td" scope="row">{row.bh_category_name}</TableCell>
                <TableCell component="td" scope="row">{row.vendor_name}</TableCell>
                <TableCell component="td" scope="row" align="center">{String(row.vendor_ind).toUpperCase()}</TableCell>
                <TableCell component="td" scope="row" align="right">
                    {row.total_sales > 0 ? FormatCurrency({ value: row.total_sales }) : '$0'}</TableCell>
                {/* {row.total_sales > 0 ? FormatCurrency({ value: row.total_sales }): ''}</TableCell> */}
                <TableCell component="td" scope="row" align="right">
                    {row.market_share > 0 ? FormatPercent({ value: row.market_share }) : '0%'}</TableCell>
                {/* {row.market_share > 0? FormatPercent({ value: row.market_share }):''}</TableCell> */}
                <TableCell component="td" scope="row" align="right">{row.vendor_rank_in_category}</TableCell>
            </TableRow>
        </React.Fragment>
    );
    // }else
    // return '';
}

export default function MarketShareCategories({ yearSelected, onYearChange, vendorIds, isCorporateUser }) {

    const [orderBy, setOrderBy] = useState("");
    const [order, setOrder] = useState("asc");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData1, setTableData1] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableBackup, setTableBackup] = useState([]);
    const textInput = React.useRef(null);
    const [tableLength, setTableLength] = useState('')
    const [vpName, setVpName] = React.useState('');
    const [vpNames, setVpNames] = React.useState(['']);
    const [catName, setCatName] = React.useState('');
    const [catNames, setCatNames] = React.useState(['']);



    const percentUSLocale = (min, max) => Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: min, maximumFractionDigits: max ? max : min });


    const marketShareColumns = [
        { key: "bh_category_name", label: "Category Name" },
        { key: "vendor_name", label: "Vendor Name" },
        { key: "vendor_ind", label: "Classification Flag" },
        { key: "total_sales", align: "right", label: "Amount" },
        { key: "market_share", align: "right", label: "Market Share" },
        { key: "vendor_rank_in_category", align: "right", label: "Rank in Category" },
    ];

    useEffect(() => {
        // let params =  {year};
        let params = { year: yearSelected };
        console.log('params', params)

        if (!isCorporateUser) {
            params['vendorId'] = vendorIds.map((row) => row.dbaId);
        }
        if (vpName) {
            params = { ...params, vendorName: vpName };
        }
        if (catName) {
            params = { ...params, bhCategoryName: catName };
            params = { ...params };
        }
        setLoading(true);

        Promise.all([
            axios.post('/api/vendor/opportunity/purchasing-survey-information', params)])
            .then(response => {
                if (response[0].status == '200') {
                    setLoading(false);
                    setTableLength(response[0].data.length)
                    setTableData1(response[0].data);
                    setTableBackup(response[0].data)

                }

                setLoading(true);

            });

        //     axios.post("/api/vendor/opportunity/purchasing-survey-information", params).then(response => {
        //         if(response.data){
        //         setLoading(false)
        //         console.log("purchasing-survey-information", response.data);
        //         setTableLength(response.data.length)
        //         setTableData1(response.data);
        //         setTableBackup(response.json);
        //         }
        //         setLoading(true);

        //     });

        //    Promise.all([
        //         axios.post('/api/common/getActiveVendorList', { ...params }),
        //     ]).then(response => {
        //         setLoading(false);
        //         setVpNames(response[0].data);
        //         console.log('vpNAmes dropdown',vpNames)

        //     });

        Promise.all([
            axios.post('/api/common/getActiveVendorList', { ...params }),
            axios.post('/api/vendor/opportunity/categories-market-share', { ...params }),
        ]).then(response => {
            setLoading(false);
            setVpNames(response[0].data)

            setCatNames(response[1].data);

        });

    }, [yearSelected, vendorIds, isCorporateUser, vpName, catName]);

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => handleRequestSort(event, property);

    const clearAll = () => {
        setVpName("")
        setCatName("")
        textInput.current.value = "";
        setTableData1(tableBackup);
        setTableLength(tableBackup.length)

    };

    const generateExport = () => {

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("MarketShare", {
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                printArea: `A1:F${tableData1.length + 4}`,
                printTitlesRow: '1:4',
                horizontalCentered: true,
                showGridLines: false,
                fitToPage: true,
                fitToHeight: 0

            },
            // views: [{ style: 'pageLayout', showGridLines: false, showRowColHeaders: false }],
            views: [{ showGridLines: false, showRowColHeaders: false }],

            headerFooter: {
                oddFooter: "Page &P of &N"
            }
        });

        // Add Image
        const insertedLogo = workbook.addImage({ base64: logoDataUrl, extension: "png" });
        const title = "BLUE HAWK Market Share";
        const title1 = `Based on collective Dashboard and Purchasing Survey data: ${yearSelected}`;
        const str = title + "\n" + title1;
        const sheetHeaderRow = ["Vendor Name", "VP", "BH Category Name", "Amount", "Market Share", "Rank in Category"];

        const titleRow = worksheet.addRow([str])
        titleRow.eachCell((cell, number) => {
            cell.alignment = { wrapText: true, vertical: 'middle' };
            // cell.border = {
            //     top: { style: "thick" },
            //     left: { style: "thick" },
            //     bottom: { style: "thick" },
            //     right: { style: "thick" },
            // };

        });
        titleRow.font = { name: "Calibri", family: 8, size: 12, bold: true, color: { argb: "0E509E" } };
        worksheet.addRow([]);
        // Add Image in "E1:F2" cell range
        // worksheet.addImage(insertedLogo, {
        //     tl: { col: 4.2, row: 0.2 },
        //     br: { col: 5.8, row: 1.8 }
        // });
        worksheet.addImage(insertedLogo, "E1:F3");
        worksheet.mergeCells("A1:D2");
        worksheet.mergeCells("E1:F2");
        worksheet.addRow([]);


        // Add Header Row
        const headerRow = worksheet.addRow(sheetHeaderRow);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: "pattern",
                pattern: "",
                fgColor: { argb: "FFAA00" },
                bgColor: { argb: "FF0000FF" },
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thick" },
                right: { style: "thin" },
            };
            // cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
            cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };
        });

        const filterData = tableData1.filter((row) => {
            if ((row.total_sales > 0 && row.market_share > 0)) {
                return tableData1
            }

        });


        filterData.forEach(function (obj) {
            let addedRow = worksheet.addRow([obj.vendor_name, (obj.vendor_ind == 'in' ? 'Y' : 'N'),
            obj.bh_category_name, obj.total_sales, (percentUSLocale(0, 1).format(obj.market_share / 100)), obj.vendor_rank_in_category]);
            // let addedRow = worksheet.addRow([obj.vendor_name, obj.vendor_ind, 
            //     obj.bh_category_name, (obj.total_sales > 0 ?obj.total_sales:''), (obj.market_share >0 ? obj.market_share / 100: ''), obj.vendor_rank_in_category]);
            addedRow.eachCell((cell, number) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                if (number === 4) {
                    cell.numFmt = '$#,##0;[Red]-$#,##0';
                }
                if (number === 5) {
                    cell.numFmt = '0.00%';

                }
                if (number === 6) {
                    cell.numFmt = '#,##0';
                }
                cell.alignment = { wrapText: true, vertical: 'left', horizontal: 'left' };

            });
        });



        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 5;
        worksheet.getColumn(3).width = 25;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 10;
        worksheet.getColumn(6).width = 10;

        // Add some meta data in excel file
        workbook.creator = 'Blue Hawk Support <bluehawk.support@tredence.com>';
        workbook.company = 'Tredence Inc.';
        workbook.category = 'Market Share Report';

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "MarketShare.xlsx");
        });
    };
    const handleMarketShareYear = (event) => {
        // setMarketShareYear(event.target.value)
        const newYear = event.target.value;
        onYearChange(newYear);
        // onYearChange(event.target.value)
        // console.log('event',event.target.value)
    }

    const requestSearch = (searchVal) => {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        console.log('requestSearch', searchVal);

        const filteredRows = tableData1.filter((row) => {
            return row.vendor_name.toString().toLowerCase().includes(removeExtraSpace(searchVal).toLowerCase())
                || row.bh_category_name.toString().toLowerCase().includes(removeExtraSpace(searchVal).toLowerCase())
        });
        console.log(filteredRows, "filteredRows");
        setTableData1(filteredRows);
        setTableLength(filteredRows.length)
        if (searchVal === "") {
            console.log('if container ', tableData1)
            setTableData1(tableBackup);
            setTableLength(tableBackup.length);
        }
    };

    // const searchFn = (row, searchQuery) => {
    // const filteredRecords = searchQuery ? tableDataBackup.filter(row => searchFn(row, searchQuery)) : tableDataBackup;
    //     return row.vendor_name.toLowerCase().includes(searchQuery.toLowerCase())
    //         || row.bh_category_name.toLowerCase().includes(searchQuery.toLowerCase())
    //         || row.vendor_ind.toLowerCase().includes(searchQuery.toLowerCase());
    // };

    // const filteredRecords = searchQuery ? tableDataBackup.filter(row => searchFn(row, searchQuery)) : tableDataBackup;
    // console.log('filteredRecords')

    const visibleRows = (rowsPerPage > 0 ? stableSort(tableData1, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData1);
    return (
        <>

            <Paper sx={{ position: 'relative' }}>
                <FormControl sx={{
                    minWidth: 120, float: 'right', position: 'absolute',
                    zIndex: '9999', top: '-79px', right: '0px'
                }} size="small" variant="filled">
                    <InputLabel id="year-select-small">Year</InputLabel>
                    <Select labelId="year-select-small" id="year-select-small" value={yearSelected}
                        label="Year" onChange={handleMarketShareYear}>
                        {years.map(year => <MenuItem key={'year-' + year * 2} value={year}>{year}</MenuItem>)}

                    </Select>
                </FormControl>

            </Paper>

            <Paper elivation={3} sx={{ p: 3 }}>
                <Toolbar disableGutters>
                    <Typography variant="h5" sx={{ mr: "auto" }}>Market Share </Typography>
                    <FormControl style={{ marginRight: '10px', minWidth: 120, }} size="small">
                        <InputLabel id="vp-name-select-small">Select Vendor</InputLabel>
                        <Select
                            labelId="vp-name-select-small"
                            id="vp-name-select-small"
                            value={vpName}
                            label="Select Vendor"
                            sx={{ minWidth: 250, marginBottom: '4px', minHeight: '43px', marginRight: 3 }}
                            onChange={(event) => { setVpName(event.target.value) }}
                            size='small'
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {vpNames.map(vp => (<MenuItem key={vp.vendorId} value={vp.vendorName}>{vp.vendorName}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginRight: '10px', minWidth: 120, }} size="small">
                        <InputLabel>Select Category</InputLabel>
                        <Select
                            labelId="cat-name-select-small"
                            id="cat-name-select-small"
                            value={catName}
                            label="Select Category"
                            sx={{ minWidth: 250, marginBottom: '4px', minHeight: '43px', marginRight: 3 }}
                            onChange={(event) => { setCatName(event.target.value) }}
                            size='small'>
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {catNames.map(cat => (<MenuItem key={cat.bh_category_id} value={cat.bh_category_name}>{cat.bh_category_name}</MenuItem>))}

                        </Select>
                    </FormControl>
                    {/* <MaterialSearch value={searchQuery} onChange={event => setSearchQuery(event.target.value)} placeholder='Search' /> */}
                    <TextField inputRef={textInput} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder='Search' />
                    <Button variant="contained" onClick={() => clearAll()} sx={{ ml: 1, float: 'right' }}>Clear All</Button>
                    <Button variant="contained" sx={{ ml: 2, float: 'right' }} onClick={() => generateExport()}><FileDownloadOutlinedIcon />Export</Button>
                </Toolbar>
                <LoadingProgress loading={!loading} />

                <TableContainer component={Paper}>
                    <div class="tablebody" style={{ height: '367px', overflowY: 'auto' }}>
                        <Table aria-label="collapsible table" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {marketShareColumns.map((headerCell) => (
                                        <TableCell key={headerCell.key} align={headerCell.align ? headerCell.align : "left"} sortDirection={orderBy === headerCell.key ? order : false}>
                                            <TableSortLabel active={orderBy === headerCell.key} direction={orderBy === headerCell.key ? order : "asc"} onClick={createSortHandler(headerCell.key)} >
                                                {headerCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/* {tableData1.map((row, rowIdx) => <MarketShareTableRow key={row.bh_category_id + '123'+rowIdx} row={row} />)} */}
                                {/* {visibleRows.map((row, rowIdx) => <MarketShareTableRow key={rowIdx} row={row} />)} */}
                                {(rowsPerPage > 0
                                    ? stableSort(tableData1, getComparator(order, orderBy)).slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    : tableData1
                                ).map((row, rowIdx) => (
                                    <MarketShareTableRow key={row.bh_category_id + '123' + rowIdx} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <TableFooter style={{ float: 'right' }}>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: -1 }]}
                                colSpan={marketShareColumns.length}
                                count={tableLength}
                                // count={filteredRows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{ inputProps: { "aria-label": "rows per page" }, native: true }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>

                </TableContainer>

            </Paper>
        </>
    );
}

function LoadingProgress({ loading }) {
    return loading ? (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    ) : null
}
